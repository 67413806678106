import { useContext, useState } from 'react';

import {
  Box,
  alpha,
  Stack,
  lighten,
  Divider,
  IconButton,
  Tooltip,
  styled, AppBar, ListItemIcon, ListItemText,
  useTheme, ListItem, Grid, Typography, Button, Drawer, MenuList, ListItemButton
} from '@mui/material';
// import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import MenuIcon from '../../../../src/assets/icons/MENU VERDE_24X24.svg';
import SalirIcon from '../../../../src/assets/icons/CERRAR_24X24.svg';
import LogoHeader from '../../../../src/assets/imagen/LOGO INFOFACT_96X19.svg';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderMenu from './Menu';
import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import { ResponseLogin } from 'src/models/login';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: 60px;
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 0)};
        right: 0;
        z-index: 6;
        background-color: ${alpha(theme.header.background, 0.95)};
        backdrop-filter: blur(3px);
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);
const Header = () => {
  const response: ResponseLogin = JSON.parse(localStorage.getItem("responseLogin"));
  const theme = useTheme();
  return (
    <HeaderWrapper
      alignItems="center" sx={{backgroundColor: 'var(--colorHoverSecondary-Green)',
      borderBottom: '1px solid var(--neutrallineas, #E2E5E8)',
      padding: {xs:'8px 20px 8px 0px', sm:'8px 20px 8px 0px', md:'8px 20px 8px 88px', lg:'8px 20px 8px 20px', xl:'8px 20px 8px 20px'}
    }}
    >
      <HeaderMenu response={response} ></HeaderMenu>
    </HeaderWrapper>
  );
}

export default Header;
