import React, { useState } from 'react';
import { Router, useRoutes } from 'react-router-dom';
// import routes from 'src/router';
// import Router from "./app/router";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import ThemeProvider from './theme/ThemeProvider';
import { TokenProvider } from './contexts/UserContext';
import Ruta from './app/router';


function App() {
 
  // const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <TokenProvider>         
          <Ruta />          
        </TokenProvider>
        {/* {content}           */}
      </LocalizationProvider>
    </ThemeProvider>
  );
}

// const App = () => {
//   return (
//     <div>
//       <ThemeProvider>
//         <LocalizationProvider dateAdapter={AdapterDateFns}>
//           <CssBaseline />
//           <Router />
//         </LocalizationProvider>
//       </ThemeProvider>

//     </div>
//   );

// }


// }
export default App;
