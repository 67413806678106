import { FC, ReactNode, useContext } from 'react';
import { Box, alpha, lighten, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from './Sidebar';
import Header from './Header';
import LoaderScreen from 'src/content/pages/Status/Loading/LoaderScreen';
import { TokenContext } from 'src/contexts/UserContext';
import { ResponseLogin } from 'src/models/login';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const SidebarLayout: FC<SidebarLayoutProps> = () => {
  const theme = useTheme();
  const { loading } = useContext(TokenContext);
  return (
    <>
      <Box
        sx={{
          flex: 1,
          background: 'white',
          height: '100%',
          '.MuiPageTitle-wrapper': {
            background:
              theme.palette.mode === 'dark'
                ? theme.colors.alpha.trueWhite[5]
                : theme.colors.alpha.white[50],
            // marginBottom: `${theme.spacing(4)}`,
            boxShadow:
              theme.palette.mode === 'dark'
                ? `0 1px 0 ${alpha(
                  lighten(theme.colors.primary.main, 0.7),
                  0.15
                )}, 0px 2px 4px -3px rgba(0, 0, 0, 0.2), 0px 5px 12px -4px rgba(0, 0, 0, .1)`
                : `0px 2px 4px -3px ${alpha(
                  theme.colors.alpha.black[100],
                  0.1
                )}, 0px 5px 12px -4px ${alpha(
                  theme.colors.alpha.black[100],
                  0.05
                )}`
          },
        }}
      >
        <Header  />
        <Sidebar />
        <Box
          sx={{
            maxWidth: '100%',
            background: 'white',
            position: 'relative',
            zIndex: 4,
            // paddingBottom: '20px',
            display: 'collapse',

            // flex: 1,
            // pt: '40px',
            // pl:'30px',
            [theme.breakpoints.up('md')]: {
              // /CAMBIAR MARGEN A LA IZQUIERDA EN EL BODY
              ml: `${theme.sidebar.width}`
              // ml: `320PX`
            }
          }}
        >
          <Box display="block"
            sx={{
              // marginLeft: { xs: '0px', sm: '0px', md: '40px', lg: '40px', xl: '40px' },
              // marginRight: { xs: '0px', sm: '20px', md: '20px', lg: '20px', xl: '20px' },
              // paddingRight: { xs: '0px', sm: '0px', md: '0px', lg: '0px', xl: '0px' },
              // paddingRight:'20px',
              // paddingLeft:'20px',
              background: 'white'
            }} >
            <Outlet />
            
          </Box>
        </Box>
        {loading === true ? <LoaderScreen /> : ''}
      </Box>
    </>
  );
};
export default SidebarLayout;
