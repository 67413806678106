import { Button, ButtonProps, styled, } from '@mui/material'
interface Props {
    handleEvent: React.MouseEventHandler<HTMLElement>
    title: string
    pasive?: boolean
    imgIcon?: boolean
    urlIcon?: string
}
const ButtonAlertStyle: React.FC<Props> = ({ handleEvent, title, pasive, imgIcon, urlIcon }) => {
    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: pasive ? 'var(--colorPrimary-Error)' : '#fff',
        backgroundColor: pasive ? '#fff' : '#FF655A',
        textTransform: 'uppercase',
        textAlign: 'center',
        fontFamily: 'Poppins',
        // fontSize: {xl:'15px', lg:'15px', md:'15px', sm:'14px', xs:'14px'},
        fontSize: '15px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '22px',
        borderRadius: '30px',
        border: pasive ? '1px solid #FF7D74' : 'none',
        width: '100%',
        height: '100%',
        '.MuiButton-startIcon': {
            margin: 0,
            paddingRight: '12px',
        },
        '&:hover': {

            backgroundColor: '#FF7D74',
            color: pasive ? '#fff' : '#fff',
            boxShadow: 'none',
            border: pasive ? '1px solid #FF7D74' : 'none',
        },
        display: 'flex !important',
        // borderRadius: '30px',
        // border: '2px solid var(--colorPrimary-Green)'
    }));
    return (
        <ColorButton variant={'outlined'} onClick={handleEvent} >{title}</ColorButton>
    )
}

export default ButtonAlertStyle