import axios from "axios";
import { CONFIG } from './configuracion.service';
import { CertificadoDigital } from "src/models/certificadoDigital";

const url = CONFIG.url + CONFIG.versionApi;

export const postCertificadoB64 = async (consultaDoc: CertificadoDigital, token: string) => {
    try {

        const response = await axios.post<CertificadoDigital>(`${url}/locales/certificado`, JSON.stringify(consultaDoc),
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
        return response;
    } catch (error: any) {
        console.log('postCertificadoDigital', error.message);
        return error.response;

    }
};