import { Button, ButtonProps, styled, } from '@mui/material'
interface Props {
    handleEvent: React.MouseEventHandler<HTMLElement>
    title: string
    pasive: boolean
    imgIcon?: boolean
    urlIcon?: string
    txtPasivo?: string
    txtActivo?: string
    bgPasivo?: string
    bgActivo?: string
    bgHover?: string
    bgBorder?: string

}
const ButtonModify: React.FC<Props> = ({ handleEvent, title, pasive, imgIcon, urlIcon, txtActivo, txtPasivo, bgActivo, bgPasivo, bgHover, bgBorder }) => {
    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: pasive ? txtPasivo : txtActivo,
        backgroundColor: pasive ? bgPasivo : bgActivo,
        width: '100%',
        // textTransform: 'capitalize',
        // width: {xs:'100%', sm:'100%', md:'100%', lg:'50%', xl:'50%'},
        '@media (max-width:719px) ': {
            // color: 'var(--boton-inactivo, #9BAEB7)',
            textAlign: 'center',
            /* MOVIL/CUERPO/PARRAFO B */
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',

        },
        '@media (min-width:720px) and (max-width:1279px)': {
            // color: 'var(--boton-inactivo, #9BAEB7)',
            textalign: 'center',
            /* MOVIL/CUERPO/PARRAFO B */
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px'
        },
        '@media (min-width:1280px) and (max-width:1919px)': {
            // color: 'var(--boton-inactivo, #9BAEB7)',
            textAlign: 'center',
            /* MOVIL/CUERPO/PARRAFO B */
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
        },
        '@media (min-width:1920px)': {
            // color: 'var(--boton-inactivo, #9BAEB7)',
            textAlign: 'center',
            /* MOVIL/CUERPO/PARRAFO B */
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '20px',
        },
        // 'MuiButtonBase-root-MuiButton-root:hover':{},
        '&.MuiButtonBase-root': {
            '&.MuiButton-root': {
                // border: pasive ? '0px' : '1px solid var(--secundariomenta)',
                // border: pasive ? '' : ''
                border: bgBorder==''?'none': '1px solid '+bgBorder,

            },
            '&.MuiButton-root:hover': {
                borderRadius: '30px',
                // background: 'var(--secundarioceleste)',
                color: pasive ? txtPasivo : txtActivo,
                // background: pasive ? '' : bgHover,
                backgroundColor: pasive ? bgPasivo : bgHover,
                border: bgBorder==''?'none': '1px solid '+bgBorder,
                boxShadow: pasive ? '#fff' : '0px 6px 16px 0px rgba(30, 41, 71, 0.24)',
            }
        },
        // fontWeight: 500,
        // textAlign: 'center',
        // fontFamily: 'Poppins',
        // fontStyle: 'normal',
        display: 'flex !important',
        borderRadius: '30px',
        boxShadow: pasive ? '' : '0px 2px 10px 0px rgba(30, 41, 71, 0.14)',
        border: bgBorder==''?'none': '1px solid '+bgBorder,
    }));

    return (
        <ColorButton sx={{
            height: '48px'
        }} variant={'outlined'} onClick={handleEvent} startIcon={imgIcon === true ? <img src={urlIcon} /> : ''}>{title}</ColorButton>
    )
}

export default ButtonModify