import React, { useState } from 'react'
import CSS from 'csstype'
import { InputBase } from '@material-ui/core';
import { makeStyles } from "@mui/styles";
import { FormControl, FormHelperText, TextField } from '@mui/material';
import { kMaxLength } from 'buffer';
import Box from '@mui/material/Box/Box';

interface Props {
  label: string
  titleInput: string
  valueInput?: string
  nameValue: string
  handleChangeInputValues: (event: React.ChangeEvent<HTMLInputElement>) => void
  error?: boolean
  textError?: any
  type: string
  required: boolean
  maxLength?: string
  minLength?: string
  value?: string
  disabled?: boolean
  iconTrue?: any
  iconFalse?: any
  activaIcon?: boolean
  estadoIcon?: boolean
}

const TextFieldSNTitle: React.FC<Props> = ({ disabled, label, titleInput, nameValue, handleChangeInputValues, error, textError, type, required, maxLength, minLength, value,
  activaIcon, iconTrue, iconFalse, estadoIcon }: Props) => {
  return (
    <Box sx={{
      height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' },
      // width: { xl: '460px', lg: '460px', md: '460px', sm: '400px', xs: '320px' },
    }} >
      <TextField
        variant="standard"
        label={label}
        placeholder={titleInput}
        type={type}
        name={nameValue}
        defaultValue={value}
        value={value}
        onChange={handleChangeInputValues}
        {...(disabled && { disabled: true, helperText: disabled })}

        {...(error && { error: true, helperText: error })}
        InputProps={{
          // startAdornment: <AccountCircle />, // <== adjusted this
          // endAdornment: activaIcon ? estadoIcon ? iconTrue : iconFalse : '', // <== adjusted this

          disableUnderline: true, // <== added this 
          style: { textTransform: "uppercase" },
          endAdornment: activaIcon ? <img src={estadoIcon ? iconTrue : iconFalse} style={{ display: iconFalse !== '' ? 'flex' : 'none', }} /> : ''
        }}
        inputProps={{
          maxLength: maxLength,
          minLength: minLength,
          style: { textTransform: "uppercase" }
        }}
        sx={{
          width: '100%',
          paddingBottom: '0px',
          '&.MuiFormControl-root': {
            borderRadius: '4px',
            height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' },
            boxShadow: disabled ? '0px 0px 0px 0px #fff' : '0px 4px 12px 0px rgba(25, 67, 127, 0.12)',
            border: disabled ? '1px solid #EFF1F4' : activaIcon ? estadoIcon ? '1px solid #42C67E' : '1px solid var(--boton-error, #FF655A)' : '1px solid var(--neutralgrisclaro, #E2E5E8)',
            background: disabled ? 'var(--neutralfondos, #F8F9FC)' : '#ffffff',
            ":hover": {
              boxShadow: '0px 0px 0px 0px rgba(25, 67, 127, 0.12)',
              backgroundColor: disabled ? '' : activaIcon ? estadoIcon !== true ? 'var(--color-marca-rojo-50, #FFF6F5)' : '#ffffff' : 'var(--colorHoverSecondary-Green)',
              // border: '1px solid var(--colorHoverText-Green)',
              border: disabled ? '' : activaIcon ? estadoIcon !== true ? '1px solid var(--color-marca-rojo-800, #FF655A)' : '1px solid #42C67E' : '1px solid var(--colorHoverText-Green)',

            }
          },
          '& .MuiFormLabel-root': {
            display: 'none',
          },
          "& .MuiInputBase-root": {
            height: '100%',
            color: 'var(--neutralneutro, #88919E)',
            fontFamily: 'Poppins',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 300,
            lineHeight: '16px',
            // paddingTop: value !== '' ? '20px' : '20px',
            paddingLeft: '8px',
            pr: '8px',
            '& ::placeholder': {
              color: 'var(--neutralneutro, #88919E)',
              fontFamily: 'Poppins',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 300,
              lineHeight: '16px',
            }
          }
        }}
      />
    </Box>
  )
}
export default TextFieldSNTitle