import React, { useState } from 'react'
import CSS from 'csstype'
import { makeStyles } from "@mui/styles";
import { Button, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { kMaxLength } from 'buffer';
import Box from '@mui/material/Box/Box';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { SelectChangeEvent } from '@mui/material/Select';
import IconActivo from '../../../../assets/icons/TABLAS/BUSCADOR-BUSCAR-ACTIVO_20X20.svg';
import IconPasivo from '../../../../assets/icons/TABLAS/BUSCADOR-BUSCAR-PASIVO_20X20.svg';

interface Props {
    title: string
    pplaceholder: string
    nameValue: string
    nameValueSelect: string
    handleChangeInputValues: (event: React.ChangeEvent<HTMLInputElement>) => void
    error?: boolean
    textError?: any
    type: string
    required: boolean
    value: string
    valueSelect: any,
    options: any
    handleChangeSelect: (event: SelectChangeEvent) => void,

}
const textInput: CSS.Properties = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#959595'
}

const TextSelect: React.FC<Props> = ({ title, pplaceholder, nameValue, error, textError, type, required, value, handleChangeInputValues, handleChangeSelect,
    valueSelect, options, nameValueSelect }: Props) => {
    const BootstrapInput = styled(InputBase)(({ theme }) => ({

    }));

    const useStyles = makeStyles((theme: any) => ({
        input: {
            fontSize: '12px',
            width: '100%',
            "& .Mui-error": {
                paddingLeft: '20px',
                // borderRadius: '8px',
            },
            '& .MuiFormHelperText-root': {
                color: 'var(--colorSecondary-Blue)',
            },
            "& .MuiInputBase-root": {
                height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' },
                boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.16)',
                paddingLeft: '10px',
                '& ::placeholder': {
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '12px',
                    lineHeight: '16px',
                    color: '#959595'
                },
            }
        }
    }));

    const classes = useStyles();

    return (
        <>
            <Box sx={{
                display: 'flex',
                // borderRadius: '8px',
                height: '44px',
                width: '100%'
            }}>
                {/* <Button sx={{
                    backgroundColor: '#F49D73',
                    display: 'flex',
                    padding: '10px 12px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    alignSelf: 'stretch',
                    color: '#fff',
                    borderRadius: '8px 0px 0px 8px',
                    ":hover": {
                        backgroundColor: 'var(--colorPrimary-Hover)'

                    }
                }}>{title}</Button> */}

                {/* <Box sx={{ height: '44px' }} >
                    <FormControl variant="standard" style={{
                        border: '0px',
                        borderRadius: '4px',
                        background: '#fff',
                        boxShadow: '0px 4px 12px 0px rgba(25, 67, 127, 0.12)',
                        width: '100%'
                    }}
                        {...(error && { error: true })}>
                        <Select
                            displayEmpty
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            variant='outlined'
                            value={valueSelect}
                            name={nameValueSelect}
                            onChange={handleChangeSelect}
                            input={<BootstrapInput />}
                            sx={{
                                backgroundColor: 'var(--colorSecundaryOrange)',
                                display: 'flex',
                                padding: '10px 12px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // gap: '4px',
                                // alignSelf: 'stretch',
                                color: '#fff',
                                borderRadius: '8px 0px 0px 8px',
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                lineHeight: '20px',
                                height: '44px',
                                ":hover": {
                                    backgroundColor: 'var(--colorPrimary-Hover)',
                                }
                            }}
                            inputProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        sx: {
                                            paper: {
                                                borderRadius: '10px',
                                            },
                                            borderRadius: '10px',
                                        }
                                    }
                                }
                            }}
                        >
                            {(options.map((o: any, index: any) =>
                                <MenuItem key={index} value={o.value} sx={{
                                    color: '#232323',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 300,
                                    lineHeight: '20px',
                                }}>
                                    {o.descripcion}
                                </MenuItem>
                            ))}
                        </Select>
                        {error && <FormHelperText style={{ paddingLeft: '20px', color: 'var(--colorSecondary-Blue)' }}>{error}</FormHelperText>}
                    </FormControl>
                </Box> */}

                <TextField
                    // variant="standard"
                    // placeholder={pplaceholder}
                    variant="standard"
                    label={''}
                    placeholder={pplaceholder}
                    type={type}
                    name={nameValue}
                    defaultValue={value}

                    value={value}
                    onChange={handleChangeInputValues}
                    {...(error && { error: true, helperText: error })}
                    // required
                    // id="outlined-required"       
                    // className={classes.input}

                    InputProps={{
                        // startAdornment: <AccountCircle />, // <== adjusted this
                        disableUnderline: true, // <== added this 
                        endAdornment: <img src={value.length > 0 ? IconActivo : IconPasivo} style={{ padding: '10px' }} />
                    }}
                    inputProps={{
                        disableUnderline: true, // <== added this 
                    }}

                    sx={{
                        // width: { xs: '320PX', sm: '400PX', md: '311px', lg: '311px', xl: '311px' },
                        width: '100%',
                        height: '44px',
                        fontFamily: 'Poppins',
                        '&.MuiFormControl-root': {
                            border: '1px solid var(--color-marca-menta-800, #80CFBC)',
                            // borderRadius: '0px 8px 8px 0px',
                            borderRadius: '5px',
                        },

                        '& .MuiInputBase-root': {
                            height: '44px',
                            '& .MuiOutlinedInput-notchedOutline': {
                                border: '1px solid #F49D73',
                            },
                            fontFamily: 'Poppins',
                            fontSize: '14px',
                            fontWeight: 300,

                        },
                        '& .MuiInputBase-input': {
                            height: '44px',
                            textAlign: 'left',
                            '.MuiOutlinedInput-input': {
                                padding: '0px',
                                height: '44px',
                            },
                            '& .MuiInput-input': {
                                padding: '0px'
                            }
                        },
                        '& .MuiInputBase-input.MuiInput-input': {
                            padding: '0px 0px 0px 10px',
                        }

                    }}



                />
            </Box>
        </>
    )
}

export default TextSelect
// export default InputAndTitle