import * as React from 'react';
import { useState, useContext, useEffect } from "react";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import CSS from 'csstype';
import { createStyles, lighten, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { DialogContent } from '@material-ui/core';
import { Box, CardContent, DialogTitle, Grid, Icon, IconButton, Stack, Card, Pagination, Toolbar, alpha, Tooltip, Paper, TablePagination, Switch, SelectChangeEvent } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';
import { useNavigate } from 'react-router-dom';
import DialogScreen from 'src/content/pages/Components/Dialog';
import gifError from '../../../../assets/gifs/ERROR_360X360.gif'
import { TokenContext } from 'src/contexts/UserContext';
import EnhancedTableHead from './EnhancedTableHead';
import ButtonLimpiar from '../ColorButton/ButtonLimpiar';
import ButtonAlertStyle from '../ColorButton/ButtonAlertStyle';
import { Locales } from 'src/models/localesCobranza';
import TextFieldSNTitle from '../TextField/TextFieldSNTitle';
import { postModificaLocal } from 'src/app/services/localesCobranza.service';
import iconGreen from '../../../../assets/icons/MODAL/CHECK_24X24.svg';
import iconRed from '../../../../assets/icons/MODAL/EQUIS_24X24.svg';
import EstadoPill from '../Pill/EstadoPill';
import copiarIcon from '../../../../assets/icons/TABLAS/COPIAR_24X24.svg';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}




const TableCellColumn: CSS.Properties = {
    padding: '0px 10px',
    height: '44px',
    // paddingRight: '10px',
    // paddingLeft: '10px',
    textTransform: 'capitalize',
    fontWeight: 300,
    fontSize: '14px',
    lineHeight: '18px',
}


type Order = 'asc' | 'desc';

interface Props {
    datos: any[],
    page: number,
    rowsPerPage: any,
    setCantPage: (newValue: number) => void,
    headCells: any,
    menu: string,
    selectedCount: number,
    setSelectedCount: (newValue: number) => void
    filtros?: any[],
    searchTerm?: any,
    selectSearch?: any,
    options?: any,
    setcantiFiltrado?: (newValue: number) => void,
    handleLimpia?: any,
}

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}




const TablaCobranza = ({ datos, page, rowsPerPage, setCantPage, headCells,
    menu, setSelectedCount, filtros, searchTerm, selectSearch, options, setcantiFiltrado, handleLimpia }: Props) => {
    const useStyles = makeStyles((theme: Theme) =>
        createStyles({
            root: {
                width: '100%',
            },
            paper: {
                width: '100%',
                marginBottom: theme.spacing(1),
            },
            table: {
                minWidth: '50px !important',
                marginBottom: theme.spacing(1),
            },

            tableCell: {
                padding: '0px',
                height: '10px',
                display: 'flex',
                justifyItems: 'center',
                alignItems: 'center',
            },
            tablerow: {

            },

            visuallyHidden: {
                border: 0,
                clip: 'rect(0 0 0 0)',
                height: 1,
                margin: -1,
                overflow: 'hidden',
                padding: 0,
                position: 'absolute',
                top: 10,
                width: 1,
            },
            imageIcon: {
                height: '24px',
            },
            iconRoot: {
                textAlign: 'center'
            },

            card: {

            }
        }),
    );

    const classes = useStyles();
    const { token, idToken, idRuta, idResponseLogin, loading, idLoading } = useContext(TokenContext);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof Locales>('idlocal');
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    let rows = datos;
    const navigate = useNavigate();
    const [pageDet, setPageDet] = useState(0);
    const [positionIndex, setPositionIndex] = useState(0);
    const [codigoLocal, setCodigoLocal] = useState(0);
    const [estadoLocal, setEstadoLocal] = useState(false);
    // const [codigo, setOpenModal] = useState(false);
    const [openConfirma, setOpenConfirma] = useState(false);
    const [codlocalSelect, setCodLocalSelect] = useState('');
    const [confirmacion, setConfirmacion] = useState('');
    const [iconConfirmation, setIconConfirmation] = useState(false);
    const [descripcionLocal, setdescripcionLocal] = useState('');
    const [switchActivo, setSwitchActivo] = useState({ estado: false, index: -1, idlocal: '' });

    // const [page, setPage] = React.useState(0);
    // const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const handleChangePageDet = (event: unknown, newPage: number) => {
        setPageDet(newPage - 1);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Locales) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



    const descargaLinkSunat = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    function descargarArchivo(base64String: string, nombreArchivo: string, tipo: string) {
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);

        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: tipo });
        // console.log(nombreArchivo);
        saveAs(blob, nombreArchivo);
        // saveAs(nombreArchivo);
    }

    interface EnhancedTableToolbarProps {
        numSelected: number;
    }
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            setSelectedCount(newSelected.length);
            return;
        }
        setSelected([]);
        setSelectedCount(0);
    };

    const handleModification = async (codigolocal, positionIndex, activo) => {
        try {
            // console.log('BAJAR LOCAL')
            if (confirmacion.length == 14 && confirmacion.toUpperCase() == 'ESTOY CONFORME') {
                idLoading(true);
                const modificalocal = await postModificaLocal(codigolocal, activo, token);
                if (modificalocal.status === 200 && modificalocal.data !== undefined) {
                    // handleClickBotonModifier(!modificalocal.data[0].activo, positionIndex, codigolocal);
                    idLoading(false)
                    setOpenConfirma(false);
                    setConfirmacion('')
                    window.location.reload()
                }
            }
        } catch (error: any) {
            const response = error.message;
            console.log('fetchBaja LOCAL', response);
        }
    }

    // const filteredRecords = rows.filter((row: any) => {
    //     return Object.keys(conditions).every(key => {
    //         return conditions[key] === row[key];
    //     });
    // });    

    // const getCabecera = options.find((f: any) => f.value === selectSearch);

    // const filteredRecords = [];

    const filteredRecords = rows.filter((row: any) =>
        Object.values(row).some((field: any) => field.toString().toLowerCase().includes(searchTerm.toString().toLowerCase()))
    );

    setCantPage(Math.ceil(filteredRecords.length / rowsPerPage));

    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const handlePreviewModalConfirmation = (index: any, codigoLocal, activo: any, codigolocal: any, descripcion: any) => {

        setPositionIndex(index);
        setCodigoLocal(codigoLocal);
        setEstadoLocal(activo);
        setdescripcionLocal(descripcion);
        setCodLocalSelect(codigolocal);
        setOpenConfirma(true)

    }

    function applySortFilter(array: any, comparator: any, query: any) {
        const stabilizedThis = array.map((el: any, index: any) => [el, index]);
        stabilizedThis.sort((a: any, b: any) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        if (query) {
            return filteredRecords;
        }
        return stabilizedThis.map((el: any) => el[0]);
    }
    const filtrado = applySortFilter(rows, getComparator(order, orderBy), searchTerm);

    setcantiFiltrado(filteredRecords.length);

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
        const { name, value } = event.target;
        if (name === 'nConfirma') {
            setConfirmacion(value);
            setIconConfirmation(value.toUpperCase() == 'ESTOY CONFORME' ? true : false)
        }
    };
    const limpiaConfirmaModal = () => {
        setConfirmacion('');
    }

    const handleClose = () => {
        setOpenConfirma(false)
        setConfirmacion('')
    }

    // const handleClickBotonModifier = (estado: boolean, index: number, idlocal: string) => {

    //     if (index !== index) {
    //         setSwitchActivo({ estado: true, index: index, idlocal: idlocal });
    //     }
    //     else {
    //         setSwitchActivo({ estado: !estado, index: index, idlocal: idlocal });
    //     }

    // };


    const handleSwitchChange = (id: number) => {
        const updatedData = rows.map(row => {
            if (row.id === id) {
                return { ...row, isActive: !row.isActive };
            }
            return row;
        });
        // setTableData(updatedData);
        rows = updatedData
    };

    return (
        <>
            <React.Fragment>
                <div className={classes.root} style={{}}>
                    {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                    {filteredRecords.length > 0 ?
                        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                            <TableContainer sx={{
                                maxHeight: { xs: 200, sm: 300, md: 400, lg: 500, xl: 500 },
                                '::-webkit-scrollbar': {
                                    width: '20px',
                                    height: '20px',
                                    border: '2px solid #000000da',
                                },
                                '::-webkit-scrollbar-track': {
                                    display: 'flex',
                                    background: 'var(--pastelmenta, #E8F6F3)',
                                },
                                '::-webkit-scrollbar-thumb': {
                                    backgroundColor: 'var(--neutralhover, #A7B1BF)',
                                    borderRadius: '20px',
                                    border: '6px solid var(--pastelmenta, #E8F6F3)',
                                },
                                '::-webkit-scrollbar-thumb:hover': {
                                    border: '5px solid var(--pastelmenta, #E8F6F3)',
                                },
                                '::-webkit-scrollbar-corner': {
                                    background: 'none',
                                }
                            }}>
                                <Table stickyHeader aria-label="sticky table"  >
                                    <EnhancedTableHead
                                        classes={classes}
                                        order={order}
                                        orderBy={orderBy}
                                        onRequestSort={handleRequestSort}
                                        headCellsp={headCells}
                                        onSelectAllClick={handleSelectAllClick}
                                        rowCount={rows.length}
                                        numSelected={selected.length}
                                    />
                                    <TableBody>
                                        {stableSort(filtrado, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: number) => {
                                            return (
                                                <TableRow hover tabIndex={index} key={index}

                                                    sx={{
                                                        height: '44px',
                                                        '&:hover': {
                                                            backgroundColor: `#F0F9F7`,
                                                            "& .MuiTableCell-root": {
                                                                "&.columnaResaltada": {
                                                                    cursor: 'pointer',
                                                                }
                                                            },
                                                        }
                                                    }}  >
                                                    {
                                                        menu !== 'locales' ?
                                                            <TableCell scope="row" style={TableCellColumn} sx={{ textAlign: 'left' }} > {row.ruc} </TableCell>
                                                            : ''
                                                    }
                                                    {
                                                        menu !== 'locales' ?
                                                            <TableCell style={TableCellColumn}>{row.codigolocal} </TableCell>
                                                            : <TableCell style={TableCellColumn}>
                                                                <EstadoPill title={row.codigolocal} bg='var(--color-marca-verde-200, #E7F9EF)' color='#61B09D' widthx='60px' heightx='24px'></EstadoPill>
                                                            </TableCell>

                                                    }
                                                    {/* <TableCell style={TableCellColumn}>{row.codigolocal} </TableCell> */}
                                                    <TableCell style={TableCellColumn} sx={{ fontFamily: 'Poppins', }}>{row.descripcion}</TableCell>
                                                    {
                                                        menu !== 'locales' ?
                                                            <TableCell style={TableCellColumn} sx={{ textAlign: 'left', cursor: 'pointer' }} >
                                                                <Switch {...label}
                                                                    // checked={switchActivo.index === index && switchActivo.idlocal === row.idlocal ? switchActivo.estado : row.activo}
                                                                    checked={switchActivo.index === index && switchActivo.idlocal === row.idlocal ? switchActivo.estado : row.activo}
                                                                    onChange={() => handlePreviewModalConfirmation(index, row.idlocal, switchActivo.index === index && switchActivo.idlocal === row.idlocal ? switchActivo.estado : row.activo, row.codigolocal, row.descripcion)} />
                                                            </TableCell> : ''
                                                    }
                                                    {
                                                        menu !== 'locales' ? ''
                                                            : <TableCell style={TableCellColumn} sx={{ fontFamily: 'Poppins', }}>
                                                                {/* <Box><img src={copiarIcon} style={{ verticalAlign: 'middle' }} />{row.token}</Box></TableCell> */}
                                                                {row.token}</TableCell>
                                                    }
                                                    {
                                                        menu !== 'locales' ? ''
                                                            : <TableCell style={TableCellColumn} sx={{ fontFamily: 'Poppins', }}>
                                                                <img src={row.urlLogo} height={'44px'} width={'100%'} /></TableCell>
                                                    }
                                                    {/* <TableCell style={TableCellColumn} sx={{ fontFamily: 'Poppins', }}>{row.urllogo}</TableCell> */}

                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        :
                        <Box display={'flex'} height={'44px'} alignItems={'center'}>
                            <Box width={'240px'}>
                                <Typography sx={{
                                    color: 'var(--neutralnegro, #232323)',
                                    textAlign: 'center',
                                    fontFamily: 'Poppins',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 500,
                                    lineHeight: '16px',
                                }}>No se encontraron registros</Typography>
                            </Box>
                            <Box>
                                <ButtonLimpiar handleEvent={handleLimpia} title={'Limpiar'} pasive={false} />
                            </Box>
                        </Box>
                    }

                </div>
            </React.Fragment >


            <DialogScreen open={openConfirma} handleOpen={() => setOpenConfirma(true)} handleClose={handleClose}
                paper={{
                    sx: {
                        '&.MuiPaper-root': {
                            margin: '0px',
                        },
                        borderRadius: '20px',
                        width: { xs: '320px', sm: '320px', md: '500px', lg: '500px', xl: '500px' },
                        height: { xs: '334px', sm: '334px', md: '480px', lg: '480px', xl: '480px' }
                    }
                }}
                direction={'down'} contentOfDialog={
                    <DialogContent  >
                        <Box pb={'28px'}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                paddingBottom: {
                                    xs: '20px',
                                },
                            }}>
                                <Box component="img"
                                    src={gifError}
                                    sx={{
                                        width: { xs: '120px', sm: '120px', md: '160px', lg: '160px', xl: '160px' },
                                        height: { xs: '120px', sm: '120px', md: '160px', lg: '160px', xl: '160px' }
                                    }}></Box>
                            </Box>
                            <Typography sx={{
                                color: 'var(--boton-error, #FF655A)',
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontSize: '20px',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                lineHeight: '28px',

                            }}>
                                {/* {switchActivo ? true : estadoLocal ? true : false} */}
                                {estadoLocal !== true ? '¿Deseas dar de alta al local "' + descripcionLocal + ' (' + codlocalSelect + ')" ?'
                                    : '¿Deseas dar de baja al local "' + descripcionLocal + ' (' + codlocalSelect + ')" ?'}</Typography>
                            <Typography sx={{
                                color: 'var(--color-neutral-texto, #1E2947)',
                                textAlign: 'center',
                                fontFamily: 'Poppins',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                lineHeight: '24px',
                            }}>
                                {estadoLocal !== true ? 'No olvides verificar que el pago del cliente se encuentre al día. Escribe ESTOY CONFORME para validar la alta del local' : 'El local desactivado no podrá gestionar facturas. Escribe ESTOY CONFORME para validar la baja del local.'}
                            </Typography>
                        </Box>

                        <TextFieldSNTitle key={1}
                            handleChangeInputValues={handleChangeInput}
                            label=''
                            titleInput='ESTOY CONFORME'
                            nameValue={'nConfirma'}
                            type={'text'}
                            required={true} maxLength='14'
                            activaIcon={true}
                            estadoIcon={iconConfirmation}
                            iconTrue={iconGreen}
                            iconFalse={iconRed}
                            disabled={false}
                            value={confirmacion} />

                        <Box display={'flex'} sx={{
                            pt: { xs: '32px', sm: '32px', md: '15px', lg: '15px', xl: '15px' }, width: '100%'
                        }}>
                            <Box width={'50%'} sx={{ height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' }, pr: '8px' }}>
                                <ButtonAlertStyle handleEvent={() => handleClose()} title='CANCELAR' pasive={true} />
                            </Box>
                            <Box width={'50%'} sx={{ height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' } }}>
                                <ButtonAlertStyle handleEvent={() => handleModification(codigoLocal, positionIndex, estadoLocal ? 0 : 1)} title={estadoLocal == true ? 'DAR DE BAJA' : 'DAR DE ALTA'} pasive={iconConfirmation ? false : true} />
                            </Box>

                            {/* </Box> */}
                        </Box>

                    </DialogContent >
                } />
        </>




    );

}

export default TablaCobranza;



