import React, { useEffect, useState, useContext } from "react";
import { Route, useNavigate } from "react-router-dom";
import { TokenContext } from "src/contexts/UserContext";
const PublicRoute = (props) => {
    const { token, idToken, ruta, idRuta, responseLogin, idResponseLogin } = useContext(TokenContext);
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const checkUserToken = () => {       
        if (token) {              
            setIsLoggedIn(true);
            return navigate(ruta);
        }       
        idRuta(undefined);
        idToken(undefined);
        idResponseLogin(undefined);  
        setIsLoggedIn(false);
    }
    useEffect(() => {
        
            checkUserToken();
        }, [isLoggedIn]);
    return (
        <React.Fragment>
            {
                !isLoggedIn ? props.children : null
            }
        </React.Fragment>
    );
}
export default PublicRoute;