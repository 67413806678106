import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Icon, IconButton, Link, Typography } from '@mui/material';
import AmbarAlerta from '../../../../assets/icons/SIDEBAR/ALERTA_24X24.svg';
import RojoAlerta from '../../../../assets/icons/SIDEBAR/ERROR_24X24.svg';
import cerrarAmbar from '../../../../assets/icons/SIDEBAR/CERRAR-ALERTA_40X40.svg';
import cerrarRojo from '../../../../assets/icons/SIDEBAR/CERRAR-ERROR_40X40.svg';


import { PaperProps } from '@mui/material/Paper';
import { ResponseLogin } from 'src/models/login';
import DrawerCertificadoLocal from './Drawer';

// interface Props {
//   response: ResponseLogin
// }

export interface ConfirmationDialogRawProps {
  id: string;
  keepMounted: boolean;
  value: string;
  open: boolean;
  onClose: (value?: string) => void;
  estado: string;
  fVencimiento: any;
  paper: PaperProps;
  modalLocales: boolean;
  setmodalLocales: (value?: boolean) => void;
  datos: any[],
  handleArrayLocal?: any,

  // response: ResponseLogin;
}

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function ModalAlerta(props: ConfirmationDialogRawProps) {
  const { onClose, value: valueProp, estado, fVencimiento, paper, open, modalLocales, setmodalLocales, datos, handleArrayLocal, ...other } = props;
  const [value, setValue] = React.useState(valueProp);
  const radioGroupRef = React.useRef<HTMLElement>(null);

  React.useEffect(() => {
    if (!open) {
      setValue(valueProp);
    }
  }, [valueProp, open]);

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }
  };

  const handleCancel = () => {
    onClose();
  };

  const handleOk = () => {
    onClose(value);
  };
  const handleAbreRenovacion = () => {
    handleArrayLocal();
    setmodalLocales(true);
    onClose();
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };

  function BootstrapDialogTitle(props: DialogTitleProps) {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 20,
              top: 20,
              borderRadius: 100,
              padding: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'

            }}
          // onMouseEnter={() => setIsShown5(true)}
          // onMouseLeave={() => setIsShown5(false)}
          >
            <Icon style={{
              boxShadow: '6px 6px 20px 0px rgba(25, 67, 127, 0.12)',
              // background: estado == '1' || estado == '3'  ? 'var(--colorSecondary-Green)' : '#ffffff',
              borderRadius: 100, width: '48px', height: '48px', display: 'flex',
              alignItems: 'center', justifyContent: 'center'
            }}>
              <img src={estado == '1' || estado == '3' ? cerrarAmbar : cerrarRojo} />
            </Icon>
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  }

  return (
    <Dialog
      sx={{
        width: { xs: '100%', sm: '100%', md: '45%', lg: '40%', xl: '40%' },
        justifySelf: 'right',
        '& .MuiDialog-paper': {
          // width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
          // maxHeight: 435,
          backgroundColor: '#00000000',
          // justifyContent: 'right',
          // alignItems: 'end',
        },
        '.MuiDialog-container': {
          width: 'max-contente',
          // float: 'right',
          // alignItems: 'flex-start',
          justifyContent: { xs: 'center', sm: 'center', md: 'right', lg: 'right', xl: 'right' },
          alignItems: { xs: 'end', sm: 'end', md: 'flex-start', lg: 'flex-start', xl: 'flex-start' },
        },
        '.MuiDialogContent-root': {
          padding: '0px',
          justifyContent: 'right',
          alignItems: 'end',
        },
      }}
      maxWidth="xl"
      // TransitionComponent={(direction === 'right') ? TransitionLeft : TransitionRight}
      TransitionProps={{ onEntering: handleEntering }}
      PaperProps={paper}
      open={open}
      {...other}
      BackdropProps={{
        sx: {
          opacity: '0.4 !important',
          backgroundColor: '#232323',
          '.MuiDialog-container': {
            width: 'max-contente',
            float: 'right',
            // alignItems: 'flex-start',
            justifyContent: 'right',
            alignItems: 'end',
          },
          '.MuiDialogContent-root': {
            padding: '0px',
            justifyContent: 'right',
            alignItems: 'end',
          },
        }
      }}
    // sx={{
    //   '.MuiDialog-container': {
    //     width: 'max-contente',
    //     float: 'right',
    //     alignItems: 'flex-start'
    //   },
    //   '.MuiDialogContent-root': {
    //     padding: '0px'
    //   },

    // }}
    >

      <DialogContent sx={{
        padding: { xs: '24px 20px', sm: '24px 20px', md: '28px', lg: '28px', xl: '28px' },
        float: 'right',
      }}>

        <Box sx={{
          display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex', xl: 'flex' },
        }}>
          <Box alignSelf={'center'} sx={{
            pr: { xs: '0px', sm: '0px', md: '20px', lg: '20px', xl: '20px' },
            pt: { xs: '12px', sm: '12px', md: '0px', lg: '0px', xl: '0px' },
            textAlign: 'center'
          }}>
            {/* <BootstrapDialogTitle id="title-tabla" onClose={handleCancel}>
            </BootstrapDialogTitle> */}

            <img src={estado == '1' || estado == '3' ? cerrarAmbar : cerrarRojo} onClick={handleCancel} style={{ cursor: 'pointer' }} />

          </Box>
          <Box>
            <Box sx={{
              borderRadius: '8px 0px 0px 8px',
              border: estado == '1' || estado == '3' ? '1px solid var(--color-marca-mostaza-400, #FBD6C5)' : estado == '2' ? '1px solid var(--color-marca-rojo-400, #FFD6D3)' : '',
              background: estado == '1' || estado == '3' ? 'var(--color-marca-mostaza-50, #FFFAF7)' : estado == '2' ? 'var(--color-marca-rojo-50, #FFF6F5)' : '',
              display: 'flex',
              padding: '16px 20px 16px 0px',
              alignItems: 'center',
              // gap: '20px',
            }}>

              <Box gap={'20px'} sx={{
                pl: { xs: '12px', sm: '12px', md: '20px', lg: '20px', xl: '20px' }
              }}>
                <Typography sx={{
                  color: estado == '1' || estado == '3' ? 'var(--color-marca-mostaza-900, #FFA826)' : estado == '2' ? 'var(--color-marca-rojo-900, #F44336)' : '',
                  /* MOVIL/CUERPO/PARRAFO B */
                  fontFamily: 'Poppins',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '20px',
                }}>
                  {estado == '1' || estado == '3' ? 'Su certificado vence el ' : estado == '2' ? 'Su certificado venció el ' : 'Su certificado vence el '}
                  {fVencimiento}
                </Typography>
                {estado == '1' || estado == '3' ?
                  <Typography sx={{
                    color: ' var(--color-neutral-texto, #1E2947)',
                    fontFamily: 'Poppins',
                    fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px', xl: '16px' },
                    fontStyle: 'normal',
                    fontWeight: 300,
                    lineHeight: { xs: '20px', sm: '20px', md: '24px', lg: '24px', xl: '24px' },
                  }}>
                    Su certificado digital está próximo a vencer. No olvide renovarlo para gestionar su facturación sin problemas. </Typography> :
                  estado == '2' ?
                    <Typography sx={{
                      color: ' var(--color-neutral-texto, #1E2947)',
                      fontFamily: 'Poppins',
                      fontSize: { xs: '14px', sm: '14px', md: '16px', lg: '16px', xl: '16px' },
                      fontStyle: 'normal',
                      fontWeight: 300,
                      lineHeight: { xs: '20px', sm: '20px', md: '24px', lg: '24px', xl: '24px' },
                    }}>
                      Su certificado digital se encuentra vencido. Renuévelo para gestionar su facturación con normalidad.
                    </Typography> : ''}
              </Box>
            </Box>
          </Box>

        </Box>
        <Typography sx={{
          color: estado == '1' || estado == '3' ? 'var(--color-marca-mostaza-900, #FFA826)' : estado == '2' ? 'var(--color-marca-rojo-900, #F44336)' : '',
          textAlign: 'right',
          fontFamily: 'Poppins',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '24px',
          textDecorationLine: 'underline',
          padding: '20px',
          cursor: 'pointer'
        }}
          onClick={handleAbreRenovacion}
        >¡Renuévalo aquí!</Typography>

      </DialogContent>
      {/* <DialogActions>
        <Button autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button onClick={handleOk}>Ok</Button>
      </DialogActions> */}

      {/* <DrawerCertificadoLocal response={response} openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} datos={localesArray} ></DrawerCertificadoLocal> */}
    </Dialog>
  );
}

export default ModalAlerta;