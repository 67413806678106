import { Button, ButtonProps, styled, Box, Typography } from '@mui/material'
interface Props {
    handleEvent: React.MouseEventHandler<HTMLElement>
    title: string
    pasive?: boolean
}
const ButtonLimpiar: React.FC<Props> = ({ handleEvent, title, pasive }) => {

    return (
        <Box component={'button'} onClick={handleEvent}
            sx={{
                cursor: 'pointer',
                border: 'none',
                marginLeft: 'auto',
                borderRadius: '28px',
                width: { xs: '80px', sm: '80px', md: '100px', lg: '100px', xl: '100px' },
                background: 'var(--pastelrojo, #FFECEB)', height: '24px',
                alignItems: 'center', justifyContent: 'center',
            }}>
            <Typography sx={{
                color: pasive ? '#fff' : 'var(--primariorojo, #FF655A)',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: { xs: '12px', sm: '12px', md: '14px', lg: '14px', xl: '14px' },
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '14px',
                display: 'flex',
                alignItems: 'center', justifyContent: 'center',
            }}>
                {title}
            </Typography>
        </Box>
    )
}

export default ButtonLimpiar