import dayjs, { Dayjs } from "dayjs";
import { createContext, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { useNavigate } from "react-router-dom";

// export const TokenContext = createContext();

export const TokenContext = createContext<any>(null);


// TODO Context necista un Provider el cual se encargue de poder
// guardar y retornar la informacion que guardemos en context
export const TokenProvider = (props) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [ruta, setRuta] = useState(localStorage.getItem("ruta"));
  const [responseLogin, setResponseLogin] = useState(localStorage.getItem("responseLogin"));
  const [loading, setLoading] = useState(false);
  const [selectedIndexMenu, setSelectedIndexMenu] = useState(Number(localStorage.getItem("selectedIndexMenu")));
  const [registros, setRegistro] = useState(localStorage.getItem('registroTabla'));

  // const [switchL, setSwitchL] = useState<SwitchLocal>(() => {
  //   const UpdateToSwitch: ModificaLocal[] = [];
  //   const updateLocal: SwitchLocal = {
  //     modificaLocal: UpdateToSwitch,
  //   };

  //   return updateLocal
  // });

  // const modifierSwitch = (valor: any) => {
  //   setSwitchL({ ...switchL, modificaLocal: [...switchL.modificaLocal, valor] });
  //   localStorage.setItem("basket", JSON.stringify({ ...switchL, items: [...switchL.modificaLocal, valor] }) || '[]');
  // };

  const navigate = useNavigate();

  const idToken = (idToken: any) => {
    if (idToken === null || idToken === undefined) {
      localStorage.removeItem("responseLogin");
      localStorage.removeItem("token");
      localStorage.removeItem("ruta");
      setToken(undefined);
      idLoading(false);
      return navigate('/');

    }
    localStorage.setItem("token", idToken);
    setToken(idToken!);
  };

  const idResponseLogin = (idResponseLogin: any) => {
    if (idResponseLogin === null || idResponseLogin === undefined) {
      localStorage.removeItem("responseLogin");
      localStorage.removeItem("token");
      localStorage.removeItem("ruta");
      setResponseLogin(undefined);
      idLoading(false);
      return navigate('/');

    }
    localStorage.setItem("responseLogin", idResponseLogin);
    setResponseLogin(idResponseLogin!);
  }

  const idRuta = (idRuta: any) => {
    if (idRuta === null || idRuta === undefined) {
      localStorage.removeItem("responseLogin");
      localStorage.removeItem("token");
      localStorage.removeItem("ruta");
      setRuta(undefined);
      idLoading(false);
      return navigate('/');

    }

    localStorage.setItem("ruta", idRuta);
    setRuta(idRuta!);

  };

  const idLoading = (idLoading: boolean) => {
    setLoading(idLoading);
  };

  const idSelectedIndexMenu = (idSelectedIndexMenu: number) => {
    localStorage.setItem("selectedIndexMenu", idSelectedIndexMenu.toString());
    setSelectedIndexMenu(idSelectedIndexMenu);
  };

  const idregistros = (idregistros: any, fechaInicio: Dayjs, fechaFin: Dayjs, documentoIni: string, documentoFin: string, filtros: any) => {
    const paginaComprobantes = {
      tabla: idregistros,
      filtrado: {
        "fechaInicio": fechaInicio,
        "fechaFin": fechaFin,
        // "tipoDocumento": tipoDocumento,
        "serie_inicio": documentoIni,
        "serie_fin": documentoFin,
        // "moneda": moneda,
        // "estado": estado
        "filtros": filtros
      },
    }

    localStorage.setItem("registroTabla", JSON.stringify(paginaComprobantes));
    setRegistro(idregistros);
  }



  return (
    <TokenContext.Provider
      value={{
        token,
        idToken,
        ruta,
        idRuta,
        responseLogin,
        idResponseLogin,
        loading,
        idLoading,
        selectedIndexMenu,
        idSelectedIndexMenu,
        registros,
        idregistros,
      }}
    >
      {props.children}
    </TokenContext.Provider>
  );
};
