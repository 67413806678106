import axios from "axios";
import { CONFIG } from './configuracion.service';
import { Locales } from "src/models/localesCobranza";

const url = CONFIG.url + CONFIG.versionApi;

export const getLocalesAll = async (token: string) => {
    try {
        const response = await axios.get<Locales>(`${url}/Locales/all`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            });
        return response;
    } catch (error: any) {
        const response = error.response;
        console.log('getLocalesAll-BYinfomatica', response);
        return response;
    }
};

export const postModificaLocal = async (iddocumento: string, activa: number, token: string) => {

    try {
        const response = await axios.post<Locales>(`${url}/Locales/Modifica/${iddocumento}/${activa}`, '',
            {
                headers: {
                    'Authorization': 'Bearer ' + token,
                    'Content-Type': 'application/json'
                },
            });
        return response;
    } catch (error: any) {
        const response = error.response;
        console.log('postbajaLocal', response);
        return response;
    }
};

export const getLocalesCliente = async (token: string) => {
    try {
        const response = await axios.get<Locales>(`${url}/Locales`,
            {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
            });
        return response;
    } catch (error: any) {
        const response = error.response;
        console.log('getLocales-BYinfomatica', response);
        return response;
    }
};