import { Button, ButtonProps, styled, } from '@mui/material'
interface Props {
    handleEvent: React.MouseEventHandler<HTMLElement>
    title: string
    pasive: boolean
    imgIcon?: boolean
    urlIcon?: string
}
const ColorButtonStyleModal: React.FC<Props> = ({ handleEvent, title, pasive, imgIcon, urlIcon }) => {
    const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
        color: pasive ? 'white' : 'var(--colorHoverText-Green)',
        backgroundColor: pasive ? 'var(--colorHoverText-Green)' : 'white',
        textTransform: 'uppercase',
        width: '100%',
        '@media (max-width:768px)': {
            fontSize: '14px',
            lineHeight: '16px',
            maxHeight: '48px',
            minHeight: '48px',
            // maxWidth: '130px',
            // minWidth: '130px'

        },
        '@media (min-width:769px) ': {
            fontSize: '15px',
            lineHeight: '22px',
            maxHeight: '56px',
            minHeight: '56px',
            // maxWidth: '208px',
            // minWidth: '208px'
        },
        '&.MuiButtonBase-root': {
            '&.MuiButton-root': {
                border: pasive ? '0px' : '1px solid var(--secundariomenta)'
            }
        },
        '&:hover': {
            color: '#fff',
            borderRadius: '30px',
            background: 'var(--secundarioceleste)',
            boxShadow: '0px 6px 16px 0px rgba(25, 67, 127, 0.32)'
        },
        fontWeight: 500,
        textAlign: 'center',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        display: 'flex !important',
        borderRadius: '30px',
        boxShadow: '0px 4px 14px 0px rgba(25, 67, 127, 0.16)',
        border: pasive ? '' : '1px solid var(--secundariomenta)'
    }));
    return (
        <ColorButton variant={'outlined'} sx={{
            height: { xs: '48px', sm: '48px', md: '56px', lg: '56px', xl: '56px' }
        }} onClick={handleEvent} startIcon={imgIcon === true ? <img src={urlIcon} /> : ''}>{title}</ColorButton>
    )
}

export default ColorButtonStyleModal